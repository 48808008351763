const firebaseConfig = {
    apiKey: "AIzaSyBOI-Od2gYN0GIzcX8MN0V3UK6LvRmcoHo",
    authDomain: "comfort-transport-4ff6f.firebaseapp.com",
    projectId: "comfort-transport-4ff6f",
    storageBucket: "comfort-transport-4ff6f.appspot.com",
    messagingSenderId: "181783109321",
    appId: "1:181783109321:web:c9daa897a16a64cf21b718"
};


export default firebaseConfig;